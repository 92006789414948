
            @import "@/assets/sass/vars.portal.scss";
          





























































.v-dependency {
    &__string {
    }

    &__offset {
        font-size: 10px;
    }
}
