
            @import "@/assets/sass/vars.portal.scss";
          


























































.v-stage {
    &_top {
        font-size: 18px;
        font-weight: bold;

        &-subtitle {
            display: inline;
            font-weight: normal;
            color: $gray-600;
            font-size: 15px;
        }
    }

    .vp-milestones-container {
        margin-top: 15px;
        padding-left: 0px;
    }
}
