
            @import "@/assets/sass/vars.portal.scss";
          























.v-task-progress {
    text-align: center;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
