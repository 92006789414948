
            @import "@/assets/sass/vars.portal.scss";
          








































































































































































.v-milestone {
    margin-bottom: 15px;
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 10px;
    position: relative;

    .vp-milestone-table {
        display: flex;
        align-items: center;
        div {
        }
    }

    .vp-label {
        font-size: 10px;
        color: $gray-600;
        height: 20px;
    }

    .vp-subitems-container {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .vp-milestone-ctrl {
        flex: 0 0 30px;
    }

    .vp-milestone-ident {
        flex: 0 0 46px;
        font-size: 18px;
        color: $gray-600;
        font-weight: bold;
    }

    .vp-milestone-name {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .vp-milestone-task-progress {
        flex: 0 0 70px;
    }

    .vp-milestone-status {
        flex: 0 0 90px;
    }

    .vp-milestone-doc {
        text-align: center;
        flex: 0 0 50px;
        padding-left: 0px;
        padding-right: 15px;
        // border: 1px solid red;
    }

    .vp-milestone-comments {
        text-align: center;
        flex: 0 0 50px;
        padding-left: 15px;
        padding-right: 0px;
        // border: 1px solid red;
    }

    .vp-milestone-timeline {
        flex: 0 0 230px;
        //  border: 1px solid red;
    }

    .vp-milestone-duration {
        font-size: 12px;
        text-align: center;
        flex: 0 0 50px;
        padding-left: 15px;
        padding-right: 15px;
        // border: 1px solid red;
    }

    .vp-milestone-dep {
        font-size: 12px;
        text-align: center;
        flex: 0 0 80px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .vp-milestone-gov {
        font-size: 12px;
        text-align: center;
        flex: 0 0 160px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .vp-milestone-dc {
        font-size: 12px;
        text-align: center;
        flex: 0 0 160px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
