
            @import "@/assets/sass/vars.portal.scss";
          


















































































.v-planning-board {
    .planning-board {
        .vp-container {
            margin-bottom: 20px;
        }
    }
}
