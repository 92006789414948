
            @import "@/assets/sass/vars.portal.scss";
          





















































































































































































































































































































































.v-important-dates {
    tr.task-done {
        background-color: #f7f7f7;
    }
}
