
            @import "@/assets/sass/vars.portal.scss";
          











































































































































































.v-timeline {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    .alert-planning {
        margin: 0;
        height: 100%;
        font-size: 11px;
        padding-top: 4px;
        padding-bottom: 4px;
        border: 1px solid $gray-400;
        color: $gray-600;
    }

    .timeline-flag {
        text-align: left;
        flex: 0 0 22px;
    }

    .timeline-container {
        flex: 1 1;
    }

    .progress {
        margin-bottom: 0px;
    }

    &_dates {
        margin-top: 4px;
        font-size: 12px;
        line-height: 12px;

        &-start_date {
            font-size: 9px;
            margin-right: 10px;
            margin-top: 6px;
            font-weight: 600;
            color: transparent;
        }

        &-end_date {
            font-size: 9px;
            margin-left: 10px;
            margin-top: 6px;
            font-weight: 600;
            color: transparent;
        }

        &-end_date.make-visible,
        &-start_date.make-visible {
            color: inherit;
        }

        &-week {
            font-size: 12px;
            font-weight: 600;
        }
        &-year {
            font-size: 9px;
        }

        .fa {
            // border: 1px solid red;
            margin-top: 6px;
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    &_arrow {
        font-size: 7px;
    }

    &_from {
        font-size: 12px;
    }

    &_to {
        font-size: 12px;
    }
}
