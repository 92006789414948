
            @import "@/assets/sass/vars.portal.scss";
          


























































































































































































































































































































.v-important-dates {
    .date-is-past {
        td {
            color: $gray-600;
            background-color: #fafafa;
            //1color: $gray-700 !important;
        }
    }
}
