
            @import "@/assets/sass/vars.portal.scss";
          






























.v-control {    
    width: 28px;
    display: inline-block;

    &-disabled {
        color: $gray-300;
    }
}
