
            @import "@/assets/sass/vars.portal.scss";
          




































































































































































































































































































































































































































































.flag-form-container {
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 40px;
}
