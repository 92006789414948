
            @import "@/assets/sass/vars.portal.scss";
          


























































.v-subitem-status-component {
    .user-name {
        font-size: 10px;
    }

    .status-modified {
        font-size: 10px;
    }
}

.v-subitem-status {
    padding-left: 15px;
    padding-right: 15px;
}
