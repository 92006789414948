
            @import "@/assets/sass/vars.portal.scss";
          



























































































































































#vr-menu {
    &.opened {
        width: 170px;
    }

    &.closed {
        width: 0px;
    }

    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: $default;
    overflow-x: hidden;
    padding-top: 5rem;
    transition: 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    text-align: center;

    &.sidenav a {
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 6px;
        padding: 0px;
        text-decoration: none;
        color: white;
        display: block;
        transition: 0.4s;
        white-space: nowrap;
        font-size: 13px;
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;

        &.active {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}
