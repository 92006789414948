
            @import "@/assets/sass/vars.portal.scss";
          





























































































.v-action {
    display: flex;
    align-items: center;
    border: 1px solid $gray-500;
    border-radius: 6px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;

    &.status-done {
        background: $gray-100;
        border: 1px solid $gray-300;
    }

    &.past-deadline {
        border: 1px solid $danger;
        .v-action_deadline {
            color: $danger !important;
        }
    }

    &.is-today {
        border: 1px solid $warning;
        .v-action_deadline {
            color: $warning !important;
        }
    }

    &_id {
        flex: 0 0 70px;

        text-align: center;
    }

    &_user {
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 180px;
    }

    &_deadline {
        text-align: left;
        padding-left: 25px;
        flex: 0 0 140px;
    }

    &_text {
        padding-left: 15px;
        flex: 1 0;
    }

    &_status {
        i {
            font-size: 22px;
        }
        text-align: center;
        flex: 0 0 40px;
    }

    &_btns {
        text-align: center;
        flex: 0 0 40px;
    }
}
