
            @import "@/assets/sass/vars.portal.scss";
          





















































.v-status {
    font-size: 11px;
    text-align: center;
    color: white;
    font-weight: 600;
    padding: 8px;
    border-radius: 6px;
    text-transform: uppercase;
    border: 1px solid white;

    &:hover {
        border: 1px solid $gray-700;
    }

    &.v-status-type-0 {
        background-color: $gray-600;
    }

    &.v-status-type-1 {
        background-color: $warning;
    }

    &.v-status-type-5 {
        background-color: $primary;
    }
}
