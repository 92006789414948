
            @import "@/assets/sass/vars.portal.scss";
          




















































.v-documentation {
    .open-docs.fa-muted,
    .open-docs {
        color: $gray-800 !important;
    }
}
