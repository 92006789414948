
            @import "@/assets/sass/vars.portal.scss";
          



























































.v-smart-html {
    position: relative;
    .hider-opener {
        text-align: left;
        margin-top: 0px;

        span {
            color: $gray-600;
        }
    }
    .excla {
        display: block;
        position: absolute;
        
        color: $warning;
        left: -7px;
        bottom: 0;
    }


    &__data {        
        &.is-hover {
            background: rgba(0, 0, 0, 0.1);
            border: 1px dashed $gray-500;
        }

        &.is-overflown {
            border-left: 1px dashed $gray-600;
            padding-left: 10px;
        }
        &.is-closed {
            max-height: 70px;
            overflow: hidden;
        }

        * {
            font-size: 11px;
        }
    }
}
