
            @import "@/assets/sass/vars.portal.scss";
          
































































.v-context-comments {
    .le-count {
        font-size: 10px;
        padding-left: 5px;
    }

    .add-comments.fa-muted,
    .add-comments {
        color: $gray-800 !important;
    }
}
